<template>
  <div>
    <v-container>
      <v-card class="pa-1" flat outlined dark color="transparent">
        <v-card-text>
          <div class="layout column align-center">
            <div class="v-card__title justify-center display-1 mb-2">
              <v-img :src="logo" max-height="120" max-width="120" />
            </div>
            <div class="justify-center text-none sign-login-title">
              <div><h3>Forgot Password</h3></div>
              <div><h5>We will send you an email with instructions on how to reset your password.</h5></div>
            </div>
          </div>
        </v-card-text>
        <div class="third-box">
          <v-form>
            <v-text-field
              v-model="data.email"
              outlined
              append-icon="mdi-email"
              name="email"
              label="Email"
              type="email"
              class="input-group--focused"
            />
          </v-form>
        </div>
        <div v-if="errorMsg.length>0" class="errorMsg-box">{{ errorMsg }}</div>
        <v-btn block depressed medium class="text-none login-btn" :disabled="loading" :loading="loading" @click="submitHandle">
          Email Me
        </v-btn>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import logo from '@/assets/logo1.png'
import firebase from '@/plugins/firebase'

export default {
  name: 'ResetPwd',
  data() {
    return {
      logo: logo,
      data: {
        email: ''
      },
      loading: false,
      disabledBtn: false,
      emailNotify: false,
      errorMsg: ''
    }
  },
  methods: {
    submitHandle() {
      this.loading = true
      this.errorMsg = ''
      firebase.auth().sendPasswordResetEmail(this.data.email).then(res => {
        this.errorMsg = 'Password reset email has been sent, please check.'
        // Email sent.
      }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-login-title{
    color: white;
    text-align: center;
}

.notify-box{
    padding: 20px;
}

.errorMsg-box{
  color: rgb(9, 255, 0);
}
</style>
